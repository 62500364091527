import { z } from "zod"

export const schema = z.object({
    nome: z.string().min(1, 'Campo obrigatório'),
    email: z.string().email('E-mail inválido'),
    telefone: z
      .string()
      .min(1, { message: 'Número obrigatório' })
      .min(14, 'Número inválido'),
    cidadeUF: z.string().min(1, 'Campo obrigatório'),
    experienciaAnos: z.string().min(1, 'Campo obrigatório'),
    ferramentasTraducao: z.string().min(1, 'Campo obrigatório'),
    certificacoes: z.string().min(1, 'Campo obrigatório'),
    comentarios: z.string().optional(),
  })
  
  export const optionsLanguages = [
    {
      value: 'Inglês (US)',
      label: 'Inglês (US)',
      flag: require('../../assets/countries_flags/estados-unidos.png'),
    },
    {
      value: 'Inglês (UK)',
      label: 'Inglês (UK)',
      flag: require('../../assets/countries_flags/reino-unido.png'),
    },
    {
      value: 'Português (BR)',
      label: 'Português (BR)',
      flag: require('../../assets/countries_flags/brasil.png'),
    },
    {
      value: 'Português (PT)',
      label: 'Português (PT)',
      flag: require('../../assets/countries_flags/portugal.png'),
    },
    {
      value: 'Espanhol',
      label: 'Espanhol',
      flag: require('../../assets/countries_flags/espanha.png'),
    },
    {
      value: 'Russo',
      label: 'Russo',
      flag: require('../../assets/countries_flags/russia.png'),
    },
    {
      value: 'Italiano',
      label: 'Italiano',
      flag: require('../../assets/countries_flags/italia.png'),
    },
    {
      value: 'Alemão',
      label: 'Alemão',
      flag: require('../../assets/countries_flags/alemanha.png'),
    },
    {
      value: 'Francês',
      label: 'Francês',
      flag: require('../../assets/countries_flags/franca.png'),
    },
    {
      value: 'Japonês',
      label: 'Japonês',
      flag: require('../../assets/countries_flags/japao.png'),
    },
    {
      value: 'Chinês',
      label: 'Chinês',
      flag: require('../../assets/countries_flags/china.png'),
    },
    {
      value: 'Árabe',
      label: 'Árabe',
      flag: require('../../assets/countries_flags/arabia.png'),
    },
    {
      value: 'Hindu',
      label: 'Hindu',
      flag: require('../../assets/countries_flags/india.png'),
    },
  ]
  
  export const optionsSpecialty = [
    {
      value: 'Tradução Técnica',
      label: 'Tradução Técnica',
    },
    {
      value: 'Tradução Juramentada',
      label: 'Tradução Juramentada',
    },
    {
      value: 'Editoração Eletrônica',
      label: 'Editoração Eletrônica',
    },
    {
      value: 'Interpretação Simultânea',
      label: 'Interpretação Simultânea',
    },
    {
      value: 'Interpretação Consecutiva',
      label: 'Interpretação Consecutiva',
    },
    {
      value: 'Tradução de Websites',
      label: 'Tradução de Websites',
    },
    {
      value: 'Transcrição e Legendagem',
      label: 'Transcrição e Legendagem',
    },
    {
      value: 'Dublagem e Locução',
      label: 'Dublagem e Locução',
    },
    {
      value: 'Closed Caption',
      label: 'Closed Caption',
    },
  ]
