import { z } from 'zod'
import { useForm } from 'react-hook-form'
import styled, { keyframes } from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useRef, useState } from 'react'
import Select, {
  MultiValue,
  OptionProps,
  MultiValueGenericProps,
} from 'react-select'

import {
  PiPaperclip,
  PiTranslateDuotone,
  PiMapPinSimpleAreaThin,
} from 'react-icons/pi'
import { LuAlarmCheck } from 'react-icons/lu'
import { MdMailOutline } from 'react-icons/md'
import { TiDocumentText } from 'react-icons/ti'
import { GoArrowUpRight } from 'react-icons/go'
import { LiaLaptopSolid } from 'react-icons/lia'
import { FiSettings, FiUpload } from 'react-icons/fi'
import { RiLoader5Line, RiUserLine } from 'react-icons/ri'
import { IoChatbubblesOutline, IoPhonePortraitOutline } from 'react-icons/io5'

import Input from '../Input'
import {
  fetchRegisterTranslatorTexts,
  postTranslator,
  RegisterTranslatorTexts,
} from '../../services/strapiService'
import { optionsLanguages, optionsSpecialty, schema } from './consts'
import { useLocale } from '../../contexts'

const SignupTranslator: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [registerTranslatorTexts, setRegisterTranslatorTexts] =
    useState<RegisterTranslatorTexts>({} as RegisterTranslatorTexts)

  const refFileInputCoverLetter = useRef<HTMLInputElement>(null)
  const refFileInputCV = useRef<HTMLInputElement>(null)
  const refFileInputPreviousWorks = useRef<HTMLInputElement>(null)
  const refFileInputReferencies = useRef<HTMLInputElement>(null)

  const { locale } = useLocale()

  const getRegisterTranslatorTexts = async () => {
    try {
      const { data } = await fetchRegisterTranslatorTexts(locale)

      setRegisterTranslatorTexts(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do banner do tradutor:', error)
    }
  }

  useEffect(() => {
    getRegisterTranslatorTexts()
  }, [locale])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.TypeOf<typeof schema>>({
    resolver: zodResolver(schema),
  })

  const [formData, setFormData] = useState({
    idiomas: [] as MultiValue<{ value: string; label: string }>,
    especializacao: [] as MultiValue<{
      value: string
      label: string
    }>,

    //FILES
    cartaApresentacao: null as File | null,
    curriculo: null as File | null,
    trabalhosAnteriores: null as File | null,
    referencias: null as File | null,
  })

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const file = e.target.files ? e.target.files[0] : null
    setFormData({ ...formData, [fieldName]: file })
  }

  const handleSubmitTranslator = async (data: z.TypeOf<typeof schema>) => {
    if (!formData.cartaApresentacao)
      return alert('Campo de Carta de Apresentação obrigatório!')
    if (!formData.curriculo) return alert('Campo de Currículo obrigatório!')
    if (!formData.trabalhosAnteriores)
      return alert('Campo de Trabalhos Anteriores obrigatório!')

    const languages = formData.idiomas.map((idioma) => idioma.label)?.join(',')
    if (!languages) return alert('Campo de Idiomas obrigatório!')

    const specialty = formData.especializacao
      .map((idioma) => idioma.label)
      ?.join(',')
    if (!specialty) return alert('Campo de Especialidades obrigatório!')

    setLoading(true)
    try {
      await postTranslator({
        ...data,
        ...formData,
        idiomas: languages,
        especializacao: specialty,
      })
      alert('Usuário inscrito com sucesso!')
    } catch (error) {
      alert('Ocorreu um erro ao cadastrar suas informações.')
    } finally {
      setLoading(false)
    }
  }

  const MultiValueLabel = (
    props: MultiValueGenericProps<{
      value: string
      label: string
    }>
  ) => (
    <MultiValueLabelContainer>
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={20} height={20} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </MultiValueLabelContainer>
  )

  const Option = (
    props: OptionProps<{
      value: string
      label: string
      flag?: any
    }>
  ) => (
    <OptionContainer
      ref={props.innerRef}
      {...props.innerProps}
      isFocused={props?.isFocused}
    >
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={20} height={20} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </OptionContainer>
  )

  return (
    <Wrapper id='form-register-translator'>
      <LeftPanel>
        <h2>
          {' '}
          <PiTranslateDuotone size={32} color='#4D4D4D' />{' '}
          {registerTranslatorTexts?.badge}
        </h2>
        <h1>{registerTranslatorTexts?.title}</h1>
        <p>{registerTranslatorTexts?.subtitle}</p>
      </LeftPanel>
      <FormPanel>
        <FormRow>
          <InputWrapper>
            <Input
              type='text'
              register={register('nome')}
              errorMessage={errors.nome?.message}
              icon={<RiUserLine color='#A9A9A9' />}
              label={registerTranslatorTexts?.nameLabel}
              placeholder={registerTranslatorTexts?.namePlaceholder}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type='text'
              register={register('certificacoes')}
              errorMessage={errors.certificacoes?.message}
              label={registerTranslatorTexts?.certificateLabel}
              icon={<IoPhonePortraitOutline color='#A9A9A9' />}
              placeholder={registerTranslatorTexts?.certificatePlaceholder}
            />
          </InputWrapper>
        </FormRow>
        <FormRow>
          <InputWrapper>
            <Input
              type='email'
              register={register('email')}
              errorMessage={errors.email?.message}
              icon={<MdMailOutline color='#A9A9A9' />}
              label={registerTranslatorTexts?.emailLabel}
              placeholder={registerTranslatorTexts?.emailPlaceholder}
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              type='text'
              mask='(99) 99999-9999'
              register={register('telefone')}
              errorMessage={errors.telefone?.message}
              label={registerTranslatorTexts?.phoneLabel}
              icon={<IoPhonePortraitOutline color='#A9A9A9' />}
              placeholder={registerTranslatorTexts?.phonePlaceholder}
            />
          </InputWrapper>
        </FormRow>

        <FormRow>
          <InputWrapper>
            <Input
              type='text'
              register={register('cidadeUF')}
              icon={<PiMapPinSimpleAreaThin />}
              errorMessage={errors.cidadeUF?.message}
              label={registerTranslatorTexts?.cityUFLabel}
              placeholder={registerTranslatorTexts?.cityUFPlaceholder}
            />
          </InputWrapper>

          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.coverLetterLabel}
            </label>
            <FileInputWrapper
              type='button'
              onClick={() => refFileInputCoverLetter?.current?.click()}
            >
              <FiUpload size={16} color='#A9A9A9' />
              <PlaceholderFileInput>
                {formData?.cartaApresentacao?.name || '.pdf .doc'}
              </PlaceholderFileInput>
              <FileInput
                ref={refFileInputCoverLetter}
                type='file'
                accept='.pdf, .doc, .docx'
                onChange={(e) => handleFileChange(e, 'cartaApresentacao')}
              />
            </FileInputWrapper>
          </InputWrapper>
        </FormRow>
        <FormRow>
          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.languageLabel}
            </label>
            <Select
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  ':hover': {
                    border: '2px solid var(--hard-blue)',
                  },
                  border: state.isFocused
                    ? '2px solid var(--hard-blue)'
                    : '1px solid #A9A9A9',
                  maxHeight: '59px',
                }),
                container: (base) => ({
                  ...base,
                  zIndex: 200,
                  padding: '2px',
                  height: '59px',
                  maxHeight: '59px',
                  borderRadius: 0,
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  alignItems: 'center',
                  height: '59px',
                  maxHeight: '59px',
                  borderRadius: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  marginTop: '-4px',
                  maxHeight: '55px',
                  overflowY: 'scroll',
                  zIndex: 100,
                }),
              }}
              isMulti
              isSearchable={false}
              options={optionsLanguages}
              components={{ MultiValueLabel, Option }}
              onChange={(e) => setFormData({ ...formData, idiomas: e })}
              placeholder={registerTranslatorTexts?.languagePlaceholder}
            />
          </InputWrapper>

          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.cvLabel}
            </label>
            <FileInputWrapper
              type='button'
              onClick={() => refFileInputCV?.current?.click()}
            >
              <PiPaperclip size={16} color='#A9A9A9' />
              <PlaceholderFileInput>
                {formData?.curriculo?.name || '.pdf .doc'}
              </PlaceholderFileInput>
              <FileInput
                ref={refFileInputCV}
                type='file'
                accept='.pdf, .doc, .docx'
                onChange={(e) => handleFileChange(e, 'curriculo')}
              />
            </FileInputWrapper>
          </InputWrapper>
        </FormRow>
        <FormRow>
          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.specialtyLabel}
            </label>
            <Select
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: 0,
                  ':hover': {
                    border: '2px solid var(--hard-blue)',
                  },
                  border: state.isFocused
                    ? '2px solid var(--hard-blue)'
                    : '1px solid #A9A9A9',
                  maxHeight: '59px',
                }),
                container: (base) => ({
                  ...base,
                  zIndex: 100,
                  padding: '2px',
                  height: '59px',
                  maxHeight: '59px',
                  borderRadius: 0,
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  alignItems: 'center',
                  height: '59px',
                  maxHeight: '59px',
                  borderRadius: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  marginTop: '-4px',
                  maxHeight: '55px',
                  overflowY: 'scroll',
                  zIndex: 10,
                }),
              }}
              isMulti
              isSearchable={false}
              components={{ MultiValueLabel, Option }}
              options={optionsSpecialty}  // Usando diretamente optionsSpecialty
              placeholder={registerTranslatorTexts?.specialtyPlaceholder}
              onChange={(e) => setFormData({ ...formData, especializacao: e })}
            />
          </InputWrapper>

          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.previousWorkLabel}
            </label>
            <FileInputWrapper
              type='button'
              onClick={() => refFileInputPreviousWorks?.current?.click()}
            >
              <LiaLaptopSolid size={16} color='#A9A9A9' />
              <PlaceholderFileInput>
                {formData?.trabalhosAnteriores?.name || '.pdf .doc'}
              </PlaceholderFileInput>
              <FileInput
                ref={refFileInputPreviousWorks}
                type='file'
                accept='.pdf, .doc, .docx'
                onChange={(e) => handleFileChange(e, 'trabalhosAnteriores')}
              />
            </FileInputWrapper>
          </InputWrapper>
        </FormRow>
        <FormRow>
          <InputWrapper>
            <Input
              type='text'
              register={register('experienciaAnos')}
              icon={<LuAlarmCheck color='#A9A9A9' />}
              errorMessage={errors.experienciaAnos?.message}
              label={registerTranslatorTexts?.experienceLabel}
              placeholder={registerTranslatorTexts?.experiencePlaceholder}
            />
          </InputWrapper>

          <InputWrapper>
            <label className='label-radio'>
              {registerTranslatorTexts?.referenciesLabel}
            </label>
            <FileInputWrapper
              type='button'
              onClick={() => refFileInputReferencies?.current?.click()}
            >
              <TiDocumentText size={16} color='#A9A9A9' />
              <PlaceholderFileInput>
                {formData?.referencias?.name || '.pdf .doc'}
              </PlaceholderFileInput>
              <FileInput
                ref={refFileInputReferencies}
                type='file'
                accept='.pdf, .doc, .docx'
                onChange={(e) => handleFileChange(e, 'referencias')}
              />
            </FileInputWrapper>
          </InputWrapper>
        </FormRow>
        <FormRow>
          <InputWrapper>
            <Input
              type='text'
              icon={<FiSettings color='#A9A9A9' />}
              register={register('ferramentasTraducao')}
              errorMessage={errors.ferramentasTraducao?.message}
              label={registerTranslatorTexts?.toolsTranslatorLabel}
              placeholder={registerTranslatorTexts?.toolsTranslatorPlaceholder}
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              type='text'
              register={register('comentarios')}
              errorMessage={errors.comentarios?.message}
              label={registerTranslatorTexts?.commentLabel}
              icon={<IoChatbubblesOutline color='#A9A9A9' />}
              placeholder={registerTranslatorTexts?.commentPlaceholder}
            />
          </InputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButtonWrapper>
            <SubmitButton
              type='submit'
              disabled={loading}
              onClick={handleSubmit(handleSubmitTranslator)}
            >
              {!loading ? (
                <>
                  {registerTranslatorTexts?.buttonLabelSubmit}
                  <GoArrowUpRight />
                </>
              ) : (
                <div>
                  <RiLoader5Line width={12} height={12} />
                </div>
              )}
            </SubmitButton>
          </SubmitButtonWrapper>
        </FormRow>
      </FormPanel>
    </Wrapper>
  )
}

export default SignupTranslator

// Styled Components
const Wrapper = styled.div`
  display: flex;
  background: #f3f3f3;
  padding: 110px 40px 60px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const LeftPanel = styled.div`
  flex: 1;
  padding-right: 40px;

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 21px;
    color: #4d4d4d;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: var(--hard-blue);

    margin: 24px 0;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #4d4d4d;

    margin-bottom: 30px;
  }
`

const FormPanel = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  flex: ${(props) => (props.fullWidth ? '1' : '0.5')};
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;

  .label-radio {
    font-size: 15px;
    line-height: 1.2;
    color: #000;
    font-weight: 400;
    margin-top: -25px;
    padding-bottom: 6px;
    left: 0px;
    transition: all 0.2s ease;
    z-index: 2;
  }
`

const FileInputWrapper = styled.button`
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  border: none;
  height: 60px !important;
  padding: 10px 25px !important;
  gap: 8px;
  border: 1px solid #a9a9a9;
  cursor: pointer;
`

const FileInput = styled.input`
  display: none;
`

const PlaceholderFileInput = styled.div`
  font-weight: 400;
  text-align: left;
  color: #a9a9a9;
  font-size: 14px;
  margin-left: 10px;
`

const SubmitButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SubmitButton = styled.button`
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 35px;
  height: 60px;
  background: var(--hard-blue);
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px;

  div {
    flex: 1;
    svg {
      animation: ${spin} 2s linear infinite;
    }
  }
`

const MultiValueLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #4d4d4d;

  img {
    border-radius: 100%;
  }
`

const OptionContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isFocused }) => (isFocused ? '#FFF' : '#4d4d4d')};
  cursor: pointer;

  background-color: ${({ isFocused }) =>
    isFocused ? 'var(--hard-blue)' : '#FFF'};

  img {
    border-radius: 100%;
  }
`
